var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',{staticClass:"o-accomodation-module-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"expanded":_vm.state.items,"hide-default-footer":"","item-class":function (item) { return 'blue-grey lighten-4'; },"items-per-page":-1},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.state.items.indexOf(item) + 1))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(_vm._s(item.location.name))])]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [(item.isEnabled)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[(item.rooms && item.rooms.length)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.name')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.price')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.weekend')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.group')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.quantity')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('layout.misc.status')))])])]),_c('tbody',_vm._l((item.rooms),function(room){return _c('tr',{key:room.id,on:{"click":function($event){return _vm.showParticipants(room.id)}}},[_c('td',[_vm._v(_vm._s(room.name))]),_c('td',[_vm._v(_vm._s(room.price)+" "+_vm._s(_vm.currency))]),_c('td',[_vm._v(_vm._s(room.weekendPrice)+" "+_vm._s(_vm.currency))]),_c('td',[_vm._v(_vm._s(room.participantGroup.name))]),_c('td',[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('layout.misc.left'))+":")]),(room.amount || room.amount === 0)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(room.amount))]):_c('span',{staticClass:"font-weight-bold"},[_vm._v("---")])]),_c('span',[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('layout.misc.booked'))+":")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(room.numberOfParticipants))])])])]),_c('td',{staticClass:"text-right"},[(room.isEnabled)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)])}),0)]},proxy:true}],null,true)}):_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.modules.accommodationModule.list.noRoomAdded')))])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }